/* .nav-list li {
  list-style: none;
  padding: 26px 30px;
} */

* {
  margin: 0;
  padding: 0;
}

body {
  overflow-y: auto;
  font-family: 'Dosis', sans-serif;
}

h2 {
  margin-bottom: 10px;
}

.pink {
  /* background-color: #E39A9A;  */
  box-shadow:
    inset 0 -1em 7em rgb(250, 250, 250),
    0 0 0 2px #E39A9A,
    0.3em 0.3em 1em rgb(250, 250, 250);
}

.background {
  background: rgb(255, 255, 255);
  background-blend-mode: darken;
  background-size: cover;
}

.booking-button {
  width: 50vw;
  height: 10vh;
  border-radius: 12px;
  font-size: 1.2em;
  font-weight: bold;
}

.box-outer {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  max-width: 80%;
  margin: auto;
  height: 80%;
  width: 100vw;
}

.box-inner {
  width: 90%;
  justify-content: center;
}

.compare {
  align-items: center;
}

.logo {
  height: 100px;
  width: 100px;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  cursor: pointer;
  z-index: 1;
}

.nav-list {
  width: 70%;
  display: flex;
  align-items: center;
  /* justify-content: flex-end; */
  justify-content: space-evenly;
  z-index: 1;
}

.nav-list li {
  list-style: none;
  padding: 0.9vw 1vw;
}
/*   
.nav-list li {
  list-style: none;
  padding: 26px 30px;
} */

.nav-list li a {
  text-decoration: none;
  color: #000;
}

.nav-list li a:hover {
  color: grey;
}

.section {
  min-height: 100px;
  padding: 5px;
  display: flex;
  align-items: center;
  background-color: rgb(250, 250, 250);
  justify-content: space-between;
  scroll-margin-top: 100px;
  margin-top: 2vh;
  /* box-shadow: 0 0 10px 10px #E39A9A; */
  box-shadow:
    inset 0 -1em 7em #E39A9A,
    0 0 0 0px rgb(250, 250, 250),
    0.3em 0.3em 1em #E39A9A;
}

.text-big {
  font-weight: 500;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 30px;
}

.text-small {
  font-size: 18px;
}

.inner-list {
  list-style-type: none;
  margin-left: 15px;
}

.inner-list li {
  padding: 5px 0;
  /* height: 30vh; */
  margin-top: 12px;
}

.list-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/* .list-item img {
  border-radius: 15px;
  height: 200px;
  width: 200px;
  margin-right: 40px;
} */

.list-item img {
  border-radius: 15px;
  height: 15vw;
  width: 15vw;
  max-height: 15vw;
  max-width: 15vw;
  min-height: 15vw;
  min-width: 15vw;
  margin-right: 4vw;
  align-self: flex-start;
}

.list-item div {
  width: 100%;
}

.starting-at {
  color: #666666;
  font-size: 18px;
  margin-right: 20px;
  font-style: italic;
}

.price {
  font-weight: bold;
  font-size: 20px;
  font-family: "Georgia", sans-serif;
}

.slider {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.slider button {
  margin: 0 10px;
  height: 50px;
  width: 50px;
  font-size: 20px;
  font-weight: bold;
}

/* CSS for Mobile Menu (Hamburger) */
.bar {
  width: 100%;
  height: 4px;
  background-color: #333;
}

.mobile-menu {
  position: fixed;
  top: 0;
  left: -50%; /* Initially off-screen to the left */
  width: 50%; /* Set the desired width when open */
  height: 100%;
  background-color: #fff; /* Background color of the menu */
  transition: left 0.3s ease-in-out; /* Add a smooth transition effect */
  z-index: 1000; /* Ensure it appears above other content */
  justify-content: space-evenly;
}

.mobile-menu.open {
  left: 0; /* Slide in from the left when open */
}

.menu-icon {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
  margin-left: 50vw; /* Provide space for the menu icon */
}

.menu-items {
  margin-top: 5em;
  margin-left: 1em;
}

.menu-items li{
  list-style: none;
  padding: 1em;
  font-size: 1.5em;
}

.menu-items li a {
  text-decoration: none;
  color: #000;
}

.iframe-container {
  width:100%!important;
  padding:0!important;
  margin:0!important;
  height:2000px!important;
}

.iframe-form {
  width: 100% !important;
}


.quote-carousel {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 120px;
  width: 100%;
}

.quote-carousel-button {
  height: 80%;
  width: 20px;
  margin: 10px;
}

.quote-container {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  max-width: 70%;
}

.quote {
  align-self: flex-start;
  font-style: italic;
  color: #444444;
}

.author {
  align-self: flex-end;
  font-weight: bold;
}



/* Media Queries */
@media (max-width: 619px) {
  .mobile {
    display: block;
    order: 5;
  }

  .nav-list {
    display: none;
  }
}

@media (min-width: 620px) {
  .list-item img {
    border-radius: 15px;
    height: 200px;
    width: 200px;
    max-width: 200px;
    max-height: 200px;
    min-width: 200px;
    min-height: 200px;
    margin-right: 40px;
  }

  .inner-list li {
    height: initial;
  }

  .mobile {
    display: none;
  }
}




@media (min-width: 448px) and (max-width: 619px) {
  /* .nav-list li {
    list-style: none;
    padding: 5vw 6vw;
  } */
}